export * from './descargas.service';
export * from './estado-inicial.service';
export * from './historial.service';
export * from './menu.service';
export * from './parametros.service';
export * from './puente.service';
export * from './request.service';
export * from './nodedata.service';
export * from './simulaciones.service';
export * from './filters.service';
//export * from './auth.guard.service';
//export * from './auth.service';

import {GeneralService} from './general.service';
import {DescargasService} from './descargas.service';
import {EstadoInicialService} from './estado-inicial.service';
import {HistorialService} from './historial.service';
import {MenuService} from './menu.service';
import {ParametrosService} from './parametros.service';
import {PuentesService} from './puente.service';
import {RequestService} from './request.service';
import { NodedataService } from './nodedata.service';
import { SimulacionesService } from './simulaciones.service';
import { FiltersService } from './filters.service';
import { MeteoService } from './meteo.service';
//import {AuthGuard } from './auth.guard.service';
//import {AuthService } from './auth.service';


export const SERVICES = [
    DescargasService,
    EstadoInicialService,
    HistorialService,
    MenuService,
    ParametrosService,
    PuentesService,
    RequestService,
    GeneralService,
    NodedataService,
    SimulacionesService,
    FiltersService,
    MeteoService,
//    AuthGuard,
//    AuthService
];
