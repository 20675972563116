import { Injectable } from '@angular/core';
import {RequestService} from './request.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class MeteoService {
  constructor(private request: RequestService) { }


  private on_meteo_updated: Subject<any> = new Subject();

  public onMeteoUpdated(): Observable<any> {
    return this.on_meteo_updated.asObservable();
  }

  // OBTENEMOS LOS DATOS DE PARAMETROS
  getMeteo(idProject: number) {
    this.request.makeRequest('last_meteo_data', {pid: idProject }).subscribe((d) => {
      this.on_meteo_updated.next(d);
    });
  }


}
