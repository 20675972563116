import { Injectable, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MenuService } from './menu.service';

import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {RequestService} from './request.service';
import { Observable } from 'rxjs';


@Injectable()


export class PuentesService {

  constructor(private router: Router,  private _Menu: MenuService, private request: RequestService) {}
  public dataProject: DataProject;

  private on_project_updated: BehaviorSubject<any> = new BehaviorSubject(null);

  public onProjectUpdated(): Observable<any> {
    return this.on_project_updated.asObservable();
  }


  // DEVOLVEMOS LOS DATOS DEL PUENTE SELECCIONADO A PARTIR DEL ID DEL PUENTE
  getDataProject(idProject?, admin?): DataProject {
    if (admin){
      this.request.makeRequest('project', {pid: idProject}).subscribe((d) => {
        this.on_project_updated.next(d);
        this.dataProject = d;
      });
    } else {
      if (idProject) {
        this.request.makeRequest('project', {pid: idProject}).subscribe( (d) => {
          this.on_project_updated.next(d);
          this.dataProject = d;
          if( [29,30,31,32, 33].includes( parseInt(idProject))){

            this.router.navigate(['/pages/general/informacion/']);
          }
          else{
            this.router.navigate(['/pages/general/puente/']);
          }
        });
      } else {
        if (this.dataProject) {
          return this.dataProject;
        } else {
          this.router.navigate(['']);
        }
      }
    }

  }



}


export interface DataProject {
  prod: string;
  project: {
    co_id: number;
    gps_lat: string;
    gps_lon: string;
    id: number;
    ini_date: string;
    end_date: string;
    location: string;
    initial_state_date: string;
    name: string;
    nnodes: number;
    pl_id: string;
    string_identifier: string|null;
    simulations: number;
    descarga_registros: number;
    orden_plataforma: number;
  };
  sections: {
    id: number;
    image: string;
    lX: number;
    lX2: number;
    lY: number;
    lY2: number;
    label: string;
    position: string;
    position_label: string|null;
    stateSection: number|null;
    ubi_section: string;
    nodes: {
      despl_type: string|null;
      id: number;
      lX: number;
      lY: number;
      label: string;
      idf_str?:string;
      ubi_section: string;
      display: string;
      maintainment: string;
      enabled_axis: string;
      charts?:string;
      nodeType: {
        axes_count: string;
        chart_title: string;
        chart_xAxis: string;
        chart_yAxis: string;
        id: string;
        label: string;
        precision: string;
        short_label: string;
        unit: string;
        axes_labels: any;
        date_format: string;
      },
      sid: number;
      state: number;
      y_max: number;
      y_min: number;
    }[],
  }[];
}



export interface Puente {
  id: number;
  name: string;
  imgInstalacion: string;
  imgGeneral: string;
  coord:
    {
      x: string;
      y: string;
    };
  state: number;
}




export interface Section {
  sections:
    {
      id: string,
      lX: number,
      lX2: number,
      lY: number,
      lY2: number,
      label: string,
      tramo: string,
      type: string,
      nodes:
        {
          id: string,
          sid: string,
          lX: string,
          lY: string,
          label: string,
          nodeType: string,
          state: number,
          identifier: string,
          despl_type: string|null,
        }[],
    }[];
  }



  // getPuenteSeleccionado(): Puente {
  //   if (this.idPuenteSeleccionado == null) {
  //     if (localStorage.getItem('idPuenteSeleccionado') == null) {
  //       this.router.navigate(['']);
  //       return null;
  //     } else {
  //       this.router.navigate(['']);
  //       return null;
  //       // this._Menu.getMenu(parseInt(localStorage.getItem('idPuenteSeleccionado')));
  //       // return this.puentes.find(x => x.id === parseInt(localStorage.getItem('idPuenteSeleccionado')));
  //     }
  //   }else {
  //     // return this.puentes.find(x => x.id === this.idPuenteSeleccionado);
  //     // pedimos los datos

  //     if (this.idPuenteSeleccionado) {
  //       this.request.makeRequest('project', {pid: this.idPuenteSeleccionado}).subscribe((d) => {
  //         this.on_project_updated.next(d);
  //       });
  //     }else {
  //       this.router.navigate(['']);
  //     }


  //   }
  // }

  // this.puentes = [
  //   {
  //     id: 1,
  //     name: 'Puente Malleco',
  //     imgInstalacion: 'assets/images/malleco.jpg',
  //     imgGeneral: 'assets/images/mallecoGeneral.png',
  //     latitude: 39.377127449875445,
  //     longitude: -0.4007858714354313,
  //     state: 2,
  //     sections: [
  //       {
  //         id: '1',
  //         lX: '50.5',s
  //         lY: '11',
  //         lX2: '26',
  //         lY2: '25',
  //         label: 'A',
  //         state: 1,
  //         type: '3',
  //         tramo: '1',
  //         nodes: [
  //           {
  //             id: '1',
  //             sid: '1',
  //             lX: '50.5',
  //             lY: '56',
  //             label: 'A1',
  //             nodeType: '1',
  //             state: 1,
  //             identifier: 'UI1',
  //             despl_type: null,
  //           },
  //           {
  //             id: '2',
  //             sid: '1',
  //             lX: '60',
  //             lY: '52',
  //             label: 'A2',
  //             nodeType: '3',
  //             state: 1,
  //             identifier: 'UD1',
  //             despl_type: null,
  //           },
  //           {
  //             id: '3',
  //             sid: '1',
  //             lX: '40.5',
  //             lY: '51',
  //             label: 'A3',
  //             nodeType: '3',
  //             state: 1,
  //             identifier: 'UD2',
  //             despl_type: null,
  //           },
  //           {
  //             id: '4',
  //             sid: '1',
  //             lX: '50.5',
  //             lY: '21',
  //             label: 'A4',
  //             nodeType: '3',
  //             state: 1,
  //             identifier: 'UD3-2',
  //             despl_type: 'long_junta',
  //           },
  //         ],
  //       },
  //       {
  //         id: '2',
  //         lX: '59',
  //         lY: '11',
  //         lX2: '26',
  //         lY2: '25',
  //         label: 'B',
  //         state: 2,
  //         type: '3',
  //         tramo: '2',
  //         nodes: [
  //           {
  //             id: '5',
  //             sid: '2',
  //             lX: '50.5',
  //             lY: '56',
  //             label: 'B1',
  //             nodeType: '1',
  //             state: 1,
  //             identifier: 'UI1',
  //             despl_type: null,
  //           },
  //           {
  //             id: '6',
  //             sid: '2',
  //             lX: '60',
  //             lY: '52',
  //             label: 'B2',
  //             nodeType: '3',
  //             state: 1,
  //             identifier: 'UD1',
  //             despl_type: null,
  //           },
  //         ],
  //       },
  //       {
  //         id: '3',
  //         lX: '67.3',
  //         lY: '11',
  //         lX2: '26',
  //         lY2: '25',
  //         label: 'C',
  //         state: 3,
  //         type: '3',
  //         tramo: '3',
  //         nodes: [
  //           {
  //             id: '7',
  //             sid: '3',
  //             lX: '50.5',
  //             lY: '56',
  //             label: 'C1',
  //             nodeType: '1',
  //             state: 1,
  //             identifier: 'UI1',
  //             despl_type: null,
  //           },
  //         ],
  //       },
  //     ],
  //   },
  //   {
  //     id: 2,
  //     name: 'Viaducto de Ulla',
  //     imgInstalacion: 'assets/images/ulla.jpg',
  //     imgGeneral: 'assets/images/ullaGeneral.png',
  //     latitude: 39.54000832551975,
  //     longitude: -0.6415506486417826,
  //     state: 1,
  //     sections: [
  //       {
  //         id: '4',
  //         lX: '16.4',
  //         lY: '30',
  //         lX2: '26',
  //         lY2: '25',
  //         label: 'A',
  //         state: 1,
  //         type: '3',
  //         tramo: '1',
  //         nodes: [
  //           {
  //             id: '8',
  //             sid: '1',
  //             lX: '50.5',
  //             lY: '56',
  //             label: 'A1',
  //             nodeType: '1',
  //             state: 1,
  //             identifier: 'UI1',
  //             despl_type: null,
  //           },
  //           {
  //             id: '9',
  //             sid: '1',
  //             lX: '60',
  //             lY: '52',
  //             label: 'A2',
  //             nodeType: '3',
  //             state: 1,
  //             identifier: 'UD1',
  //             despl_type: null,
  //           },
  //           {
  //             id: '10',
  //             sid: '1',
  //             lX: '40.5',
  //             lY: '51',
  //             label: 'A3',
  //             nodeType: '3',
  //             state: 1,
  //             identifier: 'UD2',
  //             despl_type: null,
  //           },
  //         ],
  //       },
  //       {
  //         id: '5',
  //         lX: '27',
  //         lY: '27.7',
  //         lX2: '26',
  //         lY2: '25',
  //         label: 'B',
  //         state: 1,
  //         type: '3',
  //         tramo: '3',
  //         nodes: [
  //           {
  //             id: '11',
  //             sid: '2',
  //             lX: '50.5',
  //             lY: '56',
  //             label: 'B1',
  //             nodeType: '1',
  //             state: 1,
  //             identifier: 'UI1',
  //             despl_type: null,
  //           },
  //           {
  //             id: '12',
  //             sid: '2',
  //             lX: '60',
  //             lY: '52',
  //             label: 'B2',
  //             nodeType: '3',
  //             state: 1,
  //             identifier: 'UD1',
  //             despl_type: null,
  //           },
  //         ],
  //       },
  //       {
  //         id: '6',
  //         lX: '38.7',
  //         lY: '25',
  //         lX2: '26',
  //         lY2: '25',
  //         label: 'C',
  //         state: 1,
  //         type: '3',
  //         tramo: '2',
  //         nodes: [
  //           {
  //             id: '13',
  //             sid: '3',
  //             lX: '50.5',
  //             lY: '56',
  //             label: 'C1',
  //             nodeType: '1',
  //             state: 1,
  //             identifier: 'UI1',
  //             despl_type: null,
  //           },
  //         ],
  //       },
  //     ],
  //   },
  //   {
  //     id: 3,
  //     name: 'Puente Seminario',
  //     imgInstalacion: 'assets/images/seminario.jpg',
  //     imgGeneral: 'assets/images/seminarioGeneral.png',
  //     latitude: -37.168194624972074,
  //     longitude: -72.35882832432185,
  //     state: 3,
  //     sections: [
  //       {
  //         id: '7',
  //         lX: '51.4',
  //         lY: '19.7',
  //         lX2: '26',
  //         lY2: '25',
  //         label: 'A',
  //         state: 1,
  //         type: '3',
  //         tramo: '3',
  //         nodes: [
  //           {
  //             id: '14',
  //             sid: '1',
  //             lX: '50.5',
  //             lY: '56',
  //             label: 'A1',
  //             nodeType: '1',
  //             state: 1,
  //             identifier: 'UI1',
  //             despl_type: null,
  //           },
  //           {
  //             id: '15',
  //             sid: '1',
  //             lX: '60',
  //             lY: '52',
  //             label: 'A2',
  //             nodeType: '3',
  //             state: 1,
  //             identifier: 'UD1',
  //             despl_type: null,
  //           },
  //         ],
  //       },
  //     ],
  //   },
  // ];
