import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import {RequestService} from './request.service';

@Injectable()
export class EstadoInicialService {

  public initialState: InitialState;

  constructor(private request: RequestService) { }
  private on_initial_state_updated: BehaviorSubject<any> = new BehaviorSubject(null);


  public onInitialStateUpdated(): Observable<any> {
    return this.on_initial_state_updated.asObservable();
  }


  // OBTENEMOS LOS DATOS DE PARAMETROS
  getInitialState(idProject: number) {
    this.request.makeRequest('initial_state', {pid: idProject }).subscribe((d) => {
      this.on_initial_state_updated.next(d);
    });
  }
}


export interface InitialState {
  cardTitle: string;
  pid: number;
  nodes: {
    activeProgress: number;
    status: string;
    suffix: string;
    ubicacion: string;
    umbralAceptable: number;
    umbralExcesivo: number;
    value: number;
  }[];
}
