import { Injectable } from '@angular/core';
import {  setMenu, clearMenu, setMenuAdmin } from '../pages/pages-menu';

import { Menu } from './general.service';

@Injectable()

export class MenuService {
  modules = [];
  modulesAdmin = [];
  constructor() {}




  getMenu(nameProject: string, menu) {

    this.modules = [
      {
        title: nameProject.toUpperCase(),
        group: true,
      },
    ];

    menu.forEach(element => {
      this.modules.push(element);
    });


    setMenu(this.modules);
  }


  vaciaMenu() {
    clearMenu();
  }


  getMenuAdmin(menu) {

    this.modulesAdmin = [
      {
        title: 'PANEL ADMINISTRADOR',
        group: true,
      },
    ];

    menu.forEach(element => {
      //console.log(element)
      this.modulesAdmin.push(element);
    });

    setMenuAdmin(this.modulesAdmin);
  }



}


// this.modules = [
//   {
//     // title: label.toUpperCase(),
//     title: nameProject.toUpperCase(),
//     group: true,
//   },

  // {
  //   title: 'General',
  //   icon: 'home-outline',
  //   expanded: true,
  //   children: [
  //     {
  //       title: 'Puente',
  //       icon: 'chevron-right-outline',
  //       link: '/pages/general/puente',
  //     },
  //     {
  //       title: 'Parámetros',
  //       icon: 'chevron-right-outline',
  //       link: '/pages/general/parametros',
  //     },
  //     {
  //       title: 'Estado Inicial',
  //       icon: 'chevron-right-outline',
  //       link: '/pages/general/estado-inicial',
  //     },
  //   ],
  // },
  // {
  //   title: 'Evolución de los parámetros',
  //   icon: 'pie-chart-outline',
  //   link: '/pages/registros/graficas',
  // },
  // {
  //   title: 'Registros',
  //   icon: 'activity-outline',
  //   link: '/pages/registros2/graficas',
  // },
  // {
  //   title: 'Descargas',
  //   icon: 'cloud-download-outline',
  //   link: '/pages/descargas',
  // },
  // {
  //   title: 'Historial',
  //   icon: 'alert-triangle-outline',
  //   link: '/pages/historial/alertas',
  // },
  // {
  //   title: 'Simulaciones',
  //   icon: 'cube-outline',
  //   link: '/pages/simulaciones/solicitar',
  // },
// ];
