import { Injectable } from '@angular/core';
import { NbCalendarRange } from '@nebular/theme';
import { Observable, BehaviorSubject } from 'rxjs';
import {RequestService} from './request.service';

@Injectable()
export class ParametrosService {
  public parameters: Parametros;

  constructor(private request: RequestService) { }
  private on_parameters_updated: BehaviorSubject<any> = new BehaviorSubject(null);


  public onParametersUpdated(): Observable<any> {
    return this.on_parameters_updated.asObservable();
  }


  // OBTENEMOS LOS DATOS DE PARAMETROS
  getParametros(idProject: number, period: number, dates:NbCalendarRange<Date> = null) {
    let dates_obj = {};
    if(dates != null){
      dates_obj = {
        start_date:dates.start.toISOString().substr(0, 10),
        end_date:dates.end.toISOString().substr(0, 10)
      };
    }
    

    this.request.makeRequest('parameters', {pid: idProject, period: period, ...dates_obj }).subscribe((d) => {
      this.on_parameters_updated.next(d);
    });
  }



  // OBTENEMOS LOS DATOS DE PARAMETROS
  getParametros2(idProject: number, period: number, dates:NbCalendarRange<Date>= null) {
    let dates_obj = {};
    if(dates != null){
      dates_obj = {
        start_date:dates.start.toISOString().substr(0, 10),
        end_date:dates.end.toISOString().substr(0, 10)
      };
    }
    

    this.request.makeRequest('dated_parameters', {pid: idProject, period: period, ...dates_obj }).subscribe((d) => {
      this.on_parameters_updated.next(d);
    });
  }


}


export interface Parametros {
  cardTitle: string;
  pid: number;
  info: string;
  nodes: {
    nodeId: number;
    label: string;
    ubicacion: string;
    umbralAceptable: number;
    umbralExcesivo: number;
    status: number;
    dateValue: number;
    value: number;
    node_type: number;
    type: string;
    suffix: string;
  }[];
}



  // private parametros: Parametros[] = [
  //   {
  //     idPuente: 1,
  //     cardTitle: 'Deflexiones en tablero',
  //     nodes : [
  //       {
  //         nodeId: 1,
  //         label: 'A1',
  //         ubicacion: 'Vano 8',
  //         value: 3, // Valor mostrado en parametros
  //         suffix: 'mm',
  //         type: 'Desplazamiento',
  //         activeProgress: 30, // Porcentaje ocupado en la progress bar
  //         umbralAceptable: 5,
  //         umbralExcesivo: 10,
  //         status: 'success', // success, warning o danger
  //         dateValue: '05:00 03-09-2019',
  //       },
  //       {
  //         nodeId: 2,
  //         label: 'A2',
  //         ubicacion: 'Vano 9',
  //         value: 6, // Valor mostrado en parametros
  //         suffix: 'mm',
  //         type: 'Desplazamiento',
  //         activeProgress: 60, // Porcentaje ocupado en la progress bar
  //         umbralAceptable: 5,
  //         umbralExcesivo: 10,
  //         status: 'warning', // success, warning o danger
  //         dateValue: '01:00 02-09-2019',
  //       },
  //       {
  //         nodeId: 3,
  //         label: 'A3',
  //         ubicacion: 'Vano 10',
  //         value: 3, // Valor mostrado en parametros
  //         suffix: 'mm',
  //         type: 'Desplazamiento',
  //         activeProgress: 80, // Porcentaje ocupado en la progress bar
  //         umbralAceptable: 11,
  //         umbralExcesivo: 10,
  //         status: 'danger', // success, warning o danger
  //         dateValue: '07:00 03-09-2019',
  //       },
  //     ],
  //   },
  //   {
  //     idPuente: 1,
  //     cardTitle: 'Inclinación en cabeza de pilas',
  //     nodes : [
  //       {
  //         nodeId: 4,
  //         label: 'B1',
  //         ubicacion: 'Pilar 7',
  //         value: 0.1, // Valor mostrado en parametros
  //         suffix: 'º',
  //         type: 'Inclinación',
  //         activeProgress: 5, // Porcentaje ocupado en la progress bar
  //         umbralAceptable: 5,
  //         umbralExcesivo: 10,
  //         status: 'success', // success, warning o danger
  //         dateValue: '08:00 03-09-2019',
  //       },
  //       {
  //         nodeId: 5,
  //         label: 'B2',
  //         ubicacion: 'Pilar 8',
  //         value: 0.2, // Valor mostrado en parametros
  //         suffix: 'º',
  //         type: 'Inclinación',
  //         activeProgress: 7, // Porcentaje ocupado en la progress bar
  //         umbralAceptable: 5,
  //         umbralExcesivo: 10,
  //         status: 'success', // success, warning o danger
  //         dateValue: '10:00 02-09-2019',
  //       },
  //       {
  //         nodeId: 6,
  //         label: 'B3',
  //         ubicacion: 'Pilar 9',
  //         value: 0.1, // Valor mostrado en parametros
  //         suffix: 'º',
  //         type: 'Inclinación',
  //         activeProgress: 5, // Porcentaje ocupado en la progress bar
  //         umbralAceptable: 11,
  //         umbralExcesivo: 10,
  //         status: 'success', // success, warning o danger
  //         dateValue: '17:00 03-09-2019',
  //       },
  //     ],
  //   },
  // ];
