import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import {RequestService} from './request.service';

@Injectable()
export class FiltersService {

  public filtersList: Filters;

  constructor(private request: RequestService) { }
  private on_filters_updated: BehaviorSubject<any> = new BehaviorSubject(null);


  public onFiltersStateUpdated(): Observable<any> {
    return this.on_filters_updated.asObservable();
  }


  // OBTENEMOS LOS DATOS DE PARAMETROS
  getFilters(idProject: number) {
    this.request.makeRequest('list_filters', {pid: idProject }).subscribe((d) => {
      this.on_filters_updated.next(d);
    });
  }
}


export interface Filters {
  id: number;
  name: string;
  label: string;
  description: string;
  state: number;
  node_type: number;
  parameter1: number;
  parameter2: number;
  can_remove: number;
  times_used: number;
  times_modified: number;
  times_discarded: number;
}
