import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'INSTALACIONES',
    group: true,
  },
  {
    title: 'Instalaciones',
    icon: 'map-outline',
    link: '/pages/instalaciones',
    home: true,
  },
];


export let menu_dynamic: NbMenuItem[] = [];
export let menu_Admin: NbMenuItem[] = [];


export function setMenu(modules: NbMenuItem[]): void {
  // CONSTRUYE EL MENÚ A PARTIR DE UN VECTOR
  clearMenu();
  modules.forEach( (element) => {
    menu_dynamic.push(element);
  });
}

export function setMenuAdmin(modulesAdmin: NbMenuItem[]): void {
  // CONSTRUYE EL MENÚ A PARTIR DE UN VECTOR
  clearMenu();
  modulesAdmin.forEach( (element) => {
    menu_Admin.push(element);
  });
}


export function clearMenu(): void {
  menu_dynamic.length = 0;
  menu_Admin.length = 0;
}




