import { Injectable, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MenuService } from './menu.service';

import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {RequestService} from './request.service';
import { Observable } from 'rxjs';


@Injectable()


export class SimulacionesService {
  public stateSendSimulacion;
  private on_simulacion_updated: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private router: Router,  private _Menu: MenuService, private request: RequestService) {}


  public onSimulacionUpdated(): Observable<any> {
    return this.on_simulacion_updated.asObservable();
  }


  sendDataSimulacion(idProject, simulacionType, simulacionObjetivo, simulacionDesc) {
    this.request.makeRequest('simmulation', {pid: idProject, simType: simulacionType, simGoal: simulacionObjetivo, simDescr: simulacionDesc}).subscribe((d) => {
      this.on_simulacion_updated.next(d);
      this.stateSendSimulacion = d;
      // console.log('Estado simulación: ' + d);
    });
  }


  private on_download_simulaciones_updated: BehaviorSubject<any> = new BehaviorSubject(null);

  public onDownloadSimuUpdated(): Observable<any> {
      return this.on_download_simulaciones_updated.asObservable();
  }

  // DESCARGANIS KIS ARCHIVOS
  getDownloadSimu(id_puente: number) {
    window.open(this.request.getRuta() + `/downloadSimmulation&pid=${id_puente}`, '_blank');
  }






}
