import { Injectable } from '@angular/core';
import {RequestService} from './request.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

interface DataUpdate {
    chart: number;
    data: any;
}

interface DataRegisters {
  data: any;
}

@Injectable({
  providedIn: 'root',
})

export class NodedataService {

  constructor(private request: RequestService) {}

  public data: any;


  private on_data_updated: BehaviorSubject<DataUpdate> = new BehaviorSubject(null);

  public onDataUpdated(): Observable<any> {
    return this.on_data_updated.asObservable();
  }

  // DEVOLVEMOS LOS DATOS DEL NODO SELECCIONADO
  getDataNode(chart, idNode, date_ini, date_max, typeNode, axis) {
    this.request.makeCsvRequest('data', {nid: idNode, date_ini: `${date_ini} 00:00`, date_end : `${date_max} 23:59`, chart: typeNode, eje: axis}).subscribe((d) => {
        // console.log('Data raw', d);

        if (d.indexOf('\n') != -1) {
            let data = d.split('\n').filter(r => r.indexOf(',') != -1).map( r => r.split(',').map(parseFloat));
            // console.log('Parsed data', data);
            this.on_data_updated.next({chart: chart, data: data});
        } else {
            this.on_data_updated.next({chart: chart, data: []});
        }
    });
  }



  // PETICIÓN PARA OBTENER TODOS LOS DATOS DE UN REGISTRO-----------------------------------------------------------------------------------------------
  private on_fulldata_updated: BehaviorSubject<DataUpdate> = new BehaviorSubject(null);

  public onFullDataUpdated(): Observable<any> {
    return this.on_fulldata_updated.asObservable();
  }

  // DEVOLVEMOS LOS DATOS DEL PUENTE SELECCIONADO A PARTIR DEL ID DEL PUENTE
  getFullDataNode(chart, idNode, dateRegister, typeNode, axis) {
    // alert('llamado al servicio');
    // this.request.makeCsvRequest('data', {nid: idNode, date_ini: `${date_ini} 00:00`, date_end : `${date_max} 23:59`, chart: typeNode, eje: axis}).subscribe((d) => {
    this.request.makeCsvRequest('register', {nid: idNode, date: dateRegister, chart: typeNode, eje: axis}).subscribe((d) => {
        if (d.indexOf('\n') != -1) {
            let data = d.split('\n').filter(r => r.indexOf(',') != -1).map( r => r.split(',').map(parseFloat));
            // console.log('Parsed data', data);
            this.on_fulldata_updated.next({chart: chart, data: data});
        } else {

            this.on_fulldata_updated.next({chart: chart, data: []});
        }
    });
  }


    // PETICIÓN PARA OBTENER TODOS LOS DATOS DE UN REGISTRO REGISTROS2-----------------------------------------------------------------------------------------------
    private on_fulldata2_updated: Subject<DataUpdate> = new Subject();

    public onFullData2Updated(): Observable<any> {
      return this.on_fulldata2_updated.asObservable();
    }

    // DEVOLVEMOS LOS DATOS DEL PUENTE SELECCIONADO A PARTIR DEL ID DEL PUENTE
    getFullData2Node(chart, idNode, dateRegister, typeNode, axis) {
      // alert('llamado al servicio');
      // this.request.makeCsvRequest('data', {nid: idNode, date_ini: `${date_ini} 00:00`, date_end : `${date_max} 23:59`, chart: typeNode, eje: axis}).subscribe((d) => {
      this.request.makeCsvRequest('register', {nid: idNode, date: dateRegister, chart: typeNode, eje: axis}).subscribe((d) => {
          // console.log('Data raw', d);
          if (d.indexOf('\n') != -1) {
              let data = d.split('\n').filter(r => r.indexOf(',') != -1).map( r => r.split(',').map(parseFloat));
              // console.log('Parsed data', data);
              this.on_fulldata2_updated.next({chart: chart, data: data});
          } else {
              this.on_fulldata2_updated.next({chart: chart, data: []});
          }
      });
    }



  // PETICIÓN PARA OBTENER INCLINACIÓN DE UN ACELERÓMETRO EN PILA-----------------------------------------------------------------------------------------------
  private on_IncAcc_updated: BehaviorSubject<DataUpdate> = new BehaviorSubject(null);

  public on_IncAccCanvasUpdated(): Observable<any> {
    return this.on_IncAcc_updated.asObservable();
  }

  // DEVOLVEMOS LOS DATOS DEL NODO SELECCIONADO
  getDataIncAccCanvas(chart, idNode, date_ini, date_max, axis) {
    this.request.makeCsvRequest('data', {nid: idNode, date_ini: `${date_ini} 00:00`, date_end : `${date_max} 23:59`, chart: 1, eje: axis}).subscribe((d) => {
      // console.log('Data raw', d);
        if (d.indexOf('\n') != -1) {
          let data = d.split('\n').filter(r => r.indexOf(',') != -1).map( r => r.split(',').map(parseFloat));
            // console.log('Parsed data', data);
            this.on_IncAcc_updated.next({chart: chart, data: data});
          } else {
            this.on_IncAcc_updated.next({chart: chart, data: []});
          }
        });
      }



        // PETICIÓN PARA OBTENER INCLINACIÓN DE UN ACELERÓMETRO EN PILA-----------------------------------------------------------------------------------------------
        private on_Alabeo_updated: BehaviorSubject<DataUpdate> = new BehaviorSubject(null);

        public on_AlabeoUpdated(): Observable<any> {
          return this.on_Alabeo_updated.asObservable();
        }


      // DEVOLVEMOS LOS DATOS DEL NODO SELECCIONADO
      getDataAlabeoCanvas(chart, idNode, date_ini, date_max, axis) {
        this.request.makeCsvRequest('data', {nid: idNode, date_ini: `${date_ini} 00:00`, date_end : `${date_max} 23:59`, chart: 'alabeo', eje: axis}).subscribe((d) => {
          // console.log('Data raw', d);
          if (d.indexOf('\n') != -1) {
            let data = d.split('\n').filter(r => r.indexOf(',') != -1).map( r => r.split(',').map(parseFloat));
            // console.log('Parsed data', data);
            this.on_Alabeo_updated.next({chart: chart, data: data});
          } else {
            this.on_Alabeo_updated.next({chart: chart, data: []});
        }
      });
    }



  // PETICIÓN PARA OBTENER FLECHAS-----------------------------------------------------------------------------------------------
  private on_Flecha_updated: BehaviorSubject<DataUpdate> = new BehaviorSubject(null);

  public on_FlechasCanvasUpdated(): Observable<any> {
    return this.on_Flecha_updated.asObservable();
  }

  // DEVOLVEMOS LOS DATOS DEL NODO SELECCIONADO
  getDataFlechaCanvas(chart, idNode, date_ini, date_max, typeNode, axis) {
    this.request.makeCsvRequest('data', {nid: idNode, date_ini: `${date_ini} 00:00`, date_end : `${date_max} 23:59`, chart: typeNode, eje: axis}).subscribe((d) => {
        // console.log('Data raw', d);
        if (d.indexOf('\n') != -1) {
            let data = d.split('\n').filter(r => r.indexOf(',') != -1).map( r => r.split(',').map(parseFloat));
            // console.log('Parsed data', data);
            this.on_Flecha_updated.next({chart: chart, data: data});
        } else {
            this.on_Flecha_updated.next({chart: chart, data: []});
        }
    });
  }



  // PETICIÓN PARA EL CALCULO DE AMORTIGUACIÓN-----------------------------------------------------------------------------------------------
  private on_amort_updated: BehaviorSubject<DataUpdate> = new BehaviorSubject(null);

  public on_AmortUpdated(): Observable<any> {
    return this.on_amort_updated.asObservable();
  }

  // DEVOLVEMOS LOS DATOS DEL NODO SELECCIONADO
  getAmortiguacion(chart, idNode, date_ini, date_max, typeNode, axis) {
    this.request.makeCsvRequest('data', {nid: idNode, date_ini: `${date_ini} 00:00`, date_end : `${date_max} 23:59`, chart: typeNode, eje: axis}).subscribe((d) => {
      // console.log('Data raw', d);
      if (d.indexOf('\n') != -1) {
        let data = d.split('\n').filter(r => r.indexOf(',') != -1).map( r => r.split(',').map(parseFloat));
        // console.log('Parsed data', data);
        this.on_amort_updated.next({chart: chart, data: data});
    } else {
        this.on_amort_updated.next({chart: chart, data: []});
    }
    });
  }


    // PETICIÓN PARA OBTENER FRECUENCIA NATURAL ACELEROMETRO-----------------------------------------------------------------------------------------------
    private on_frecNatu_updated: BehaviorSubject<DataUpdate> = new BehaviorSubject(null);

    public on_FrecNatuAccUpdated(): Observable<any> {
      console.log("on_FrecNatuAccUpdated")
      return this.on_frecNatu_updated.asObservable();
    }

    // DEVOLVEMOS LOS DATOS DEL NODO SELECCIONADO
    getDataFrecuNatuAccCanvas(chart, idNode, date_ini, date_max, typeNode, axis) {
      //console.log("getDataFrecuNatuAccCanvas")
      this.request.makeCsvRequest('data', {nid: idNode, date_ini: `${date_ini} 00:00`, date_end : `${date_max} 23:59`, chart: typeNode, eje: axis}).subscribe((d) => {
          // console.log('Data raw', d);
          if (d.indexOf('\n') != -1) {
              let data = d.split('\n').filter(r => r.indexOf(',') != -1).map( r => r.split(',').map(parseFloat));
              // console.log('Parsed data', data);
              this.on_frecNatu_updated.next({chart: chart, data: data});
          } else {
              this.on_frecNatu_updated.next({chart: chart, data: []});
          }
      });
    }


  // PETICIÓN PARA OBTENER SOCAVACIÓN INCREMENTAL-----------------------------------------------------------------------------------------------
  private on_socIncremental_updated: BehaviorSubject<DataUpdate> = new BehaviorSubject(null);

  public on_SocIncCanvasUpdated(): Observable<any> {
    return this.on_socIncremental_updated.asObservable();
  }

  // DEVOLVEMOS LOS DATOS DEL NODO SELECCIONADO
  getDataSocIncreCanvas(chart, idNode, date_ini, date_max, typeNode, axis) {
    this.request.makeCsvRequest('data', {nid: idNode, date_ini: `${date_ini} 00:00`, date_end : `${date_max} 23:59`, chart: typeNode, eje: axis}).subscribe((d) => {
        // console.log('Data raw', d);
        if (d.indexOf('\n') != -1) {
          // let data = d.split('\n').filter( r => r.length > 0).map(parseFloat);
            let data = d.split('\n').filter(r => r.indexOf(',') != -1).map( r => r.split(',').map(parseFloat));
            this.on_socIncremental_updated.next({chart: chart, data: data});
        } else {
            this.on_socIncremental_updated.next({chart: chart, data: []});
        }
    });
  }



  // PETICIÓN PARA OBTENER MÁXIMA CARRERA DE TENSIONES DIARIA-----------------------------------------------------------------------------------------------
  private on_carreraTension_updated: BehaviorSubject<DataUpdate> = new BehaviorSubject(null);

  public on_carreraTensionCanvasUpdated(): Observable<any> {
    return this.on_carreraTension_updated.asObservable();
  }

  // DEVOLVEMOS LOS DATOS DEL NODO SELECCIONADO
  getDataCarreraTensionCanvas(chart, idNode, date_ini, date_max, typeNode, axis) {
    this.request.makeCsvRequest('data', {nid: idNode, date_ini: `${date_ini} 00:00`, date_end : `${date_max} 23:59`, chart: typeNode, eje: axis}).subscribe((d) => {
        // console.log('Data raw', d);
        if (d.indexOf('\n') != -1) {
            let data = d.split('\n').filter(r => r.indexOf(',') != -1).map( r => r.split(',').map(parseFloat));
            // console.log('Parsed data', data);
            this.on_carreraTension_updated.next({chart: chart, data: data});
        } else {
            this.on_carreraTension_updated.next({chart: chart, data: []});
        }
    });
  }







  // PETICIÓN PARA OBTENER LOS DATOS DE INCLINACIÓN SIN MEDIAS----------------------------------------------------------------------------------------------
  private on_incRaw_updated: BehaviorSubject<DataUpdate> = new BehaviorSubject(null);

  public on_incRawUpdated(): Observable<any> {
    return this.on_incRaw_updated.asObservable();
  }

  // DEVOLVEMOS LOS DATOS DEL NODO SELECCIONADO
  getIncRawCanvas(chart, idNode, date_ini, date_max, typeNode, axis) {
    this.request.makeCsvRequest('data', {nid: idNode, date_ini: `${date_ini} 00:00`, date_end : `${date_max} 23:59`, chart: 'raw_incl', eje: axis}).subscribe((d) => {
        // console.log('Data raw', d);
        if (d.indexOf('\n') != -1) {
          // let data = d.split('\n').filter(r => r.indexOf(',') != -1).map( r => r.split(',').map());
          // console.log('Parsed data', data);
          // this.on_incRaw_updated.next({chart: chart, data: data});


          let data = d.split('====').map( d1 => d1.split('\n').filter(r => r.indexOf(',') != -1).map( r => r.split(',').map(parseFloat)));
          // console.log('Parsed data', data);
          this.on_incRaw_updated.next({chart: chart, data: data.length > 1 ? data : data[0]});
        } else {
          this.on_incRaw_updated.next({chart: chart, data: []});
        }
      });
    }

    // DEVOLVEMOS LOS DATOS DEL NODO SELECCIONADO
    // PETICIÓN PARA OBTENER LOS DATOS DE INCLINACIÓN SIN MEDIAS----------------------------------------------------------------------------------------------
    private on_accIncRaw_updated: BehaviorSubject<DataUpdate> = new BehaviorSubject(null);

    public on_accIncRawUpdated(): Observable<any> {
      return this.on_accIncRaw_updated.asObservable();
    }

  getAccIncRawCanvas(chart, idNode, date_ini, date_max, typeNode, axis) {
    this.request.makeCsvRequest('data', {nid: idNode, date_ini: `${date_ini} 00:00`, date_end : `${date_max} 23:59`, chart: 'raw_incl', eje: axis}).subscribe((d) => {
        // console.log('Data raw', d);
        if (d.indexOf('\n') != -1) {
            // let data = d.split('\n').filter(r => r.indexOf(',') != -1).map( r => r.split(',').map());
            // console.log('Parsed data', data);
            // this.on_incRaw_updated.next({chart: chart, data: data});


            let data = d.split('====').map( d1 => d1.split('\n').filter(r => r.indexOf(',') != -1).map( r => r.split(',').map(parseFloat)));
            // console.log('Parsed data', data);
            this.on_accIncRaw_updated.next({chart: chart, data: data.length > 1 ? data : data[0]});
        } else {
            this.on_accIncRaw_updated.next({chart: chart, data: []});
        }
    });
  }





















  // REGISTROS 2-/-/-/-/

    // PETICIÓN PARA OBTENER LA LISTA DE REGISTROS DE UN NODO-----------------------------------------------------------------------------------------------
    private on_registerList_updated: Subject<DataRegisters> = new Subject();

    public on_registerListUpdated(): Observable<any> {
      return this.on_registerList_updated.asObservable();
    }

    // DEVOLVEMOS LOS DATOS DEL NODO SELECCIONADO
    getRegisterList( idNode) {
      this.request.makeRequest('get_register_list', {nid: idNode}).subscribe((d) => {
          // console.log('Data raw', d);
          if (Object.values(d).length >= 1) {
              // let data = d.split('\n').filter(r => r.indexOf(',') != -1).map( r => r.split(',').map(parseFloat));
              // console.log('Parsed data', d);
              this.on_registerList_updated.next({data: d});
          } else {
              this.on_registerList_updated.next({data: []});
          }
      });
    }



    // PETICIÓN PARA LA TRANSFORMADA DE FOURIER-----------------------------------------------------------------------------------------------
    private on_fourier_updated: BehaviorSubject<DataUpdate> = new BehaviorSubject(null);

    public on_FourierUpdated(): Observable<any> {
      return this.on_fourier_updated.asObservable();
    }

    // DEVOLVEMOS LOS DATOS DEL NODO SELECCIONADO
    getFourier( chart, idNode, date_ini, date_max, axis) {
      this.request.makeCsvRequest('fourier', {nid: idNode, date_ini: date_ini, date_end : date_max, eje: axis}).subscribe((d) => {
        // console.log('Data raw', d);
        if (d.indexOf('\n') != -1) {
            let data = d.split('\n').filter(r => r.indexOf(',') != -1).map( r => r.split(',').map(parseFloat));
            // console.log('Parsed data', data);
            this.on_fourier_updated.next({chart: chart, data: data});
        } else {
            this.on_fourier_updated.next({chart: null, data: []});
        }
      });
    }



    // PETICIÓN PARA EL CALCULO DE LA FELCHA-----------------------------------------------------------------------------------------------
    private on_flechas_updated: BehaviorSubject<DataUpdate> = new BehaviorSubject(null);

    public on_FlechaUpdated(): Observable<any> {
      return this.on_flechas_updated.asObservable();
    }

    // DEVOLVEMOS LOS DATOS DEL NODO SELECCIONADO
    getFlecha( chart, idNode, date_ini, date_max, axis) {
      this.request.makeCsvRequest('flecha', {nid: idNode, date_ini: date_ini, date_end : date_max, eje: axis}).subscribe((d) => {
        // console.log('Data raw', d);
        if (d.indexOf('\n') != -1) {
            // let data = d.split('\n').filter(r => r.indexOf(',') != -1).map( r => r.split(',').map(parseFloat));
            let data = d.split('\n').filter( r => r.length > 0).map(parseFloat);
            // console.log('Parsed data', data);
            this.on_flechas_updated.next({chart: chart, data: data});
        } else {
            this.on_flechas_updated.next({chart: null, data: []});
        }
      });
    }





    // PETICIÓN PARA OBTENER SOCAVACIÓN PSD-----------------------------------------------------------------------------------------------
    private on_socPSD_updated: BehaviorSubject<DataUpdate> = new BehaviorSubject(null);

    public on_SocPSDUpdated(): Observable<any> {
      return this.on_socPSD_updated.asObservable();
    }

    // DEVOLVEMOS LOS DATOS DEL NODO SELECCIONADO
    getDataSocPSD(chart, idNode, date, typeNode, axis) {
      this.request.makeCsvRequest('register', {nid: idNode, date: date, chart: typeNode, eje: axis}).subscribe((d) => {
          // console.log('Data raw', d);
          if (d.indexOf('\n') != -1) {
              let data = d.split('\n').filter(r => r.indexOf(',') != -1).map( r => r.split(',').map(parseFloat));
              this.on_socPSD_updated.next({chart: chart, data: data});
          } else {
              this.on_socPSD_updated.next({chart: chart, data: []});
          }
      });
    }



}
