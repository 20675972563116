import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {NbAuthService} from '@nebular/auth';
import {tap} from 'rxjs/operators';

import {data_source} from '../../../environments/data.source';

@Injectable()
export class AuthService {

    // public token: string = 'Njk2NV8xNTk2MTEyNDQ3';
    public token: string = data_source.token;
    constructor(private router: Router) {}

    login(token: string) {
        // console.log('Service login');
        this.token = token;
        // console.log(this.token);
        this.router.navigate(['/pages']);
    }

    logout() {
        // console.log('logging out');
        this.token = null;
        this.router.navigate(['/auth/login']);
    }

    isAuthenticated(redirect: boolean = false) {
        if (redirect && this.token === null) {
            this.router.navigate(['/auth/login']);
            return false;
        }
        // console.log('authenticated', this.token !== null);
        return this.token != null;
    }

    gToken() {
        return this.token == null || this.token == 'true' ? null : this.token;
    }
}
