import { Injectable } from '@angular/core';
import {RequestService} from './request.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class HistorialService {
  constructor(private request: RequestService) { }


  private on_alerts_updated: Subject<any> = new Subject();

  public onAlertsUpdated(): Observable<any> {
    return this.on_alerts_updated.asObservable();
  }

  // OBTENEMOS LOS DATOS DE PARAMETROS
  getAlerts(idProject: number, period = 30) {
    this.request.makeRequest('alerts', {pid: idProject, period: period }).subscribe((d) => {
      this.on_alerts_updated.next(d);
    });
  }


}

export interface Alerta {
  id: number;
  date: number;
  node: number;
  label: string;
  node_type: number;
  section: number;
  section_label: string;
  type: string;
  value: number;
  level: string;
}
