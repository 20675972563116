import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
// import { of } from 'rxjs/observable/of';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';

import {RequestService} from './request.service';

@Injectable()
export class DescargasService {

    private on_descargas_updated: BehaviorSubject<any> = new BehaviorSubject(null);

    private on_hours_updated: BehaviorSubject<any> = new BehaviorSubject(null);


    constructor(private request: RequestService) {}

    public onDownloadsUpdated(): Observable<any> {
        return this.on_descargas_updated.asObservable();
    }

    public onHoursUpdated(): Observable<any> {
        return this.on_hours_updated.asObservable();
    }

    // OBTENEMOS LOS DATOS PARA LOS ARCHIVOS
    getFiles(id_puente: number) {
        // pedimos los datos
        this.request.makeRequest('downloads', {pid: id_puente}).subscribe((d) => {
            // console.log('respuesta de downloads', d);
            // cuando los obtenemos, avisamos a los que se hayan
            // suscrito(en este caso solo el componente informes)
            // para actualizar los datos de descargas y los pinte
            this.on_descargas_updated.next(d);
            // console.log(d);
        });
    }

    // OBTENEMOS LAS HORAS EN LAS QUE HAY REGISTROS DISPONIBLES (DATOS)
    getHours(id_puente: number, timestamp?) {
        this.request.makeRequest('get_data_hours', {pid: id_puente, date: timestamp}).subscribe((d) => {
            this.on_hours_updated.next(d);
            // console.log(d);
        });
    }



    private on_download_file_updated: BehaviorSubject<any> = new BehaviorSubject(null);

    public onDownloadFileUpdated(): Observable<any> {
        return this.on_download_file_updated.asObservable();
    }

    // DESCARGANIS KIS ARCHIVOS
    getDownloadFile(id_puente: number, id_file) {
        window.open(this.request.getRuta() + `/downloadFile&pid=${id_puente}&did=${id_file}`, '_blank');
    }







    private on_download_datos_updated: BehaviorSubject<any> = new BehaviorSubject(null);

    public onDownloadDatosUpdated(): Observable<any> {
        return this.on_download_datos_updated.asObservable();
    }

    // DESCARGANIS KIS ARCHIVOS
    getDownloadDatos(id_puente: number, date_ini, date_end) {
        console.log("Descargando los datos")
        //window.open(this.request.getRuta() + `/registro&pid=${id_puente}&date_ini=${date_ini} 00:00&date_end=${date_end} 23:59&dtoken=t1571302848200`, '_blank');
        window.open(this.request.getRuta() + `/registro&pid=${id_puente}&date_ini=${date_ini}T00:00&date_end=${date_end}T23:59&dtoken=t1571302848200`, '_blank');
      }





    private on_download_registros_updated: BehaviorSubject<any> = new BehaviorSubject(null);

    public onDownloadRegistrosUpdated(): Observable<any> {
        return this.on_download_registros_updated.asObservable();
    }

    // DESCARGANIS KIS ARCHIVOS
    getDownloadRegistros(id_puente: number, date_ini, date_end ) {
        console.log("Descargando el registro")
        window.open(this.request.getRuta() + `/registro&pid=${id_puente}&date_ini=${date_ini}&date_end=${date_end}&dtoken=t1571302848200`, '_blank');
    }
}


export interface Archivo {
    name: string;
    size: string;
    items: number;
    kind: string;
    children: any[];
}
